import { PageProps, navigate } from 'gatsby'
import { useEffect } from 'react'
import Seo from '../../components/seo'
import home_page from '../../content/home_page'
import LoaderSpinner from '../../components/LoaderSpinner/LoaderSpinner'

const { meta_title, meta_description } = home_page

const Redirect = ({ params, location }: PageProps) => {
  const redirectRoute = params[`route`]

  if (redirectRoute)
    window.location.href = `/${redirectRoute}${location.search}`

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate('/')
    }, 5000)
    return clearTimeout(timeout)
  }, [])

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div>
        <LoaderSpinner />
      </div>
    </div>
  )
}

export default Redirect

export const Head = () => {
  return (
    <Seo title={`Redirect | ${meta_title}`} description={meta_description} />
  )
}
